import React from 'react';

import Zscalerlayout from '../zscalerlayout/index';

import SEO from 'components/seo/index';

// import Boschbanner from 'components/bosch/banner';

import Zscalerbanner from 'components/zscaler/banner';

import Objectforest from 'components/zscaler/objectiveforest';

import Wherewhen from 'components/zscaler/where-when';

import Projectexecute from 'components/zscaler/project-execute';

import RecentPlantations from 'components/zscaler/recentplantation';

import SimpleReactLightbox from 'simple-react-lightbox';

import Videophotograpy from 'components/zscaler/video-photograpy';

import Projectinitiate from 'components/zscaler/project-initiate';
const Zscalerplantation = () => {
  return (
    <>
      <SEO title='Zscaler Forest Plantation Drive' />
      <Zscalerlayout>
        <section id='zscaler-banner'>
          <Zscalerbanner />
        </section>
        <section id='project-initiate'>
          <Projectinitiate />
        </section>

        <section id='objectives-forests'>
          <Objectforest />
        </section>
        <section id='where-when'>
          <Wherewhen />
        </section>

        <section id='project-execute'>
          <Projectexecute />
        </section>

        <section id='video-photography'>
          <Videophotograpy />
        </section>

        {
          <SimpleReactLightbox>
            <section id='recentplantation'>
              <RecentPlantations />
            </section>
          </SimpleReactLightbox>
        }
      </Zscalerlayout>
    </>
  );
};

export default Zscalerplantation;
