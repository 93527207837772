import React from "react";

import { objectforest } from "assets/data/objectforestzscaler";

export default function Objectforest() {
  return (
    <div className="objectiveforest inner-pages pb-0">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="title-theme-heading zscaler-theme-blue">
              {" "}
              Why this initiative?{" "}
            </h3>
            <p className="initive-paratext zscaler-p-tag">
              At Zscaler we believe that transformation happens when people with
              big ideas come together to drive change. The ongoing climate
              crisis is not news to anyone. Statements like this in{" "}
              <a
                style={{ color: "#012673" }}
                href="https://www.ipcc.ch/report/ar6/wg2/resources/spm-headline-statements"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                the recent IPCC report{" "}
              </a>{" "}
              gives us a sliver of hope -{" "}
              <span className="zscaler-italic-manrope">
                {" "}
                “Near-term actions that limit global warming to close to 1.5°C
                would substantially reduce projected losses and damages related
                to climate change in human systems and ecosystems …”.{" "}
              </span>{" "}
              Zscaler employees want to do their bit, however small it might be,
              because we believe in the theory of marginal gains. Below are the
              intended objectives of this drive:
            </p>
          </div>
          <div className="row object-section-row">
            {objectforest.map((item) => {
              return (
                <div className="col-md-2 col-sm-4" key={item.id}>
                  <div className="object-box-wrapper">
                    <div className="object-img">
                      <img
                        data-src={item.img}
                        className="lazyload"
                        alt={item.alt}
                      />
                    </div>
                    <div className="object-content1">
                      <p dangerouslySetInnerHTML={{ __html: item.title }}></p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
