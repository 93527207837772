import React from 'react';

// import vadorashramam from 'assets/images/bosch/vadora-ashramam.png';

// import volunteerhand from 'assets/images/bosch/helping-hand-voluteer.png';

// import atftree from 'assets/images/bosch/ATF_Image.png';

import zforestation from 'assets/images/zscaler/z-forestation.png';

// import projectexecutepic from 'assets/images/zscaler/execute.jpg';

export default function Projectinitiate() {
  return (
    <div className='project-execute inner-pages bg-light-blue-wr' style={{ marginBottom: '30px' }}>
      <div className='container'>
          <div className='row pad20-0'>
          <div className='col-md-6'>
          <iframe width="100%" height="360" src="https://www.youtube.com/embed/COtlTWa7bKw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <p className='execute-p-content zscaler-p-tag'>
          Watch all 4 drives as part of this Z-forestation initiative in this 3 min video.
          </p>
          </div>
          <div className='col-md-6' style={{ textAlign: 'center' }}>
          <img className='vadora-img' src={zforestation} alt='How was this project executed?' />
          </div>

        
        </div>
      </div>
    </div>
  );
}
