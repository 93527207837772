import React from 'react';

import kanhashanthivanam from 'assets/images/bosch/kanha-shanthi-vanam.png';

// import kathriguppevillage from 'assets/images/bosch/kathrivillage.png';

// import coimbatoremetro from 'assets/images/bosch/coimbatore-metro.png';

import coimbatoresatellite from 'assets/images/bosch/coimbatore-satellite.png';

import bangaloresatellite from 'assets/images/bosch/bangalore-saletallite.png';

import { Link } from 'gatsby';

import Registernowpopup from 'components/bosch/registerpopup';

import nericigavillage from 'assets/images/zscaler/nericiga-village.png';

import wagohli from 'assets/images/zscaler/wagohli.png';

import chandigarhuniversity from 'assets/images/zscaler/Chandigarh_University.png';

import punevillage from 'assets/images/zscaler/Pune-FBH-Site.png';

// import bangaluruvillage from 'assets/images/zscaler/BLR-FBH-Site-2.jpg';

import bangaluruvillage from 'assets/images/zscaler/BLR-FBH-Site-1.png';

import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';

// import { truncate } from 'lodash';

import Arrowforward from 'assets/images/icons/arrow-forward.png';

import Arrowleftnext from 'assets/images/icons/arrow-left-next.png';

import { zscalerwhereData } from 'assets/data/zscalerwherewhen';

export default function Wherewhen() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      // partialVisibilityGutter: 30,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className='carousel-button-group'>
        {/* <div className='arrow-navigations custom-arrow-navigation-event'>
          <button className='pr-custom zcaler-top-slide-button' onClick={() => previous()}>
            <i className='icon icon-arrow-left-short'></i>
          </button>
          <button className='pr-rightcustom zcaler-top-slide-button zscalar-right-custom' onClick={() => next()}>
            <i className='icon icon-arrow-right-short'></i>
          </button>
        </div> */}
      </div>
    );
  };

  return (
    <div className='recent-plantation-section bosch-recent-plant hyd-recent-plant  zscalar-plantation-section'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='section-title'>
              <h3 className='title-theme-heading zscaler-theme-blue' style={{ textAlign: 'left' }}>
                {' '}
                Where &amp; When?{' '}
              </h3>
            </div>
            <div className='section pad-photography'>
              <div className='carousel-section'>
                <Carousel
                  arrows={true}
                  autoPlaySpeed={3000}
                  draggable
                  infinite
                  keyBoardControl
                  minimumTouchDrag={80}
                  partialVisible
                  renderButtonGroupOutside={true}
                  swipeable
                  responsive={responsive}
                  customButtonGroup={<ButtonGroup />}>
                  {zscalerwhereData.map((item) => {
                    return (
                      <div className='card where-card zscaler-pad-slide' key={item.id}>
                        {/* <img src={bangaluruvillage} className='img-fluid' alt='Shanthivanam' /> */}
                        <img data-src={item.img} className='lazyload' alt={item.alt} />
                        <div className='card-body where-body zscaler-gthaptik zcaler-body-box'>
                          <p className='zscaler-card-text'>
                            <span>
                              <b> {item.villagename} </b>
                            </span>{' '}
                            {item.city}
                          </p>
                          <p className='zscaler-card-text'>
                            {item.saplingname}{' '}
                            <span>
                              <b>{item.saplingcount} </b>{' '}
                            </span>
                          </p>
                          <p className='zscaler-card-text'>
                            <span> {item.acre} </span>
                          </p>

                          <p className='zscaler-card-text'>
                            {item.dateplantation} <span> </span>
                          </p>

                          <div>
                            <Link
                              className='viewdrive-tag zscaler-dark-blue'
                              to={item.drivelink}
                              target='_blank'
                              rel='noreferrer'>
                              <span>{item.officeName}</span>
                            </Link>

                            <Link
                              className='viewmap-tag zscaler-dark-blue'
                              to={item.maplink}
                              target='_blank'
                              rel='noreferrer'>
                              View in Map
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
