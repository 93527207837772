import React from 'react';
// import plantationvector from 'assets/images/bosch/plantation-vector.png';
// import downarrow from 'assets/images/bosch/downarrow.png';

import zscalerceo from 'assets/images/zscaler/Jay-microsite.jpg';

// import zscalerwhitelogo from 'assets/images/zscaler/zscaler-white-logo.png';

export default function Zscalerbanner() {
  return (
    <div className='zscaler-banner pb-0'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 col-md-6'>
            <h3 className='zscaler-banner-title'>
              {' '}
              ZSCALER MINI FOREST <span className='dis-block'> PLANTATION DRIVE </span>{' '}
            </h3>

            <p className='content-p-text banner-p-content zscaler-p-tag banner-pad-text-60'>
              {' '}
              Zscaler is celebrating 15 years of its existence and to commemorate this significant milestone, volunteers
              of the company will be planting 15,000 saplings throughout India, continuing its history of engaging
              employees to give back to communities.
            </p>

            <p className='content-p-text banner-p-content zscaler-p-tag banner-pad-text-60'>
              {' '}
              Zscaler recognizes the opportunity to make a lasting impact on the environment through planting saplings
              of endangered indigenous flora, improving biodiversity, and ecological sustainability. The plantation
              drive will benefit local communities in India where Zscaler has operations, including Hyderabad,
              Bangalore, Pune and Mohali.
            </p>
            <p className='content-p-text banner-p-content zscaler-p-tag banner-pad-text-60'>
              {' '}
              To learn more about Zscaler's environment, social and governance (ESG) initiatives, please visit{' '}
              <a
                style={{ color: '#012673' }}
                href='https://www.zscaler.com/corporate-responsibility'
                target='_blank'
                rel='noreferrer'>
                {' '}
                https://www.zscaler.com/corporate-responsibility
              </a>{' '}
            </p>
            {/* <img className='arrow-padding' src={downarrow} alt='bannar tree' /> */}
          </div>
          <div className='col-lg-6 col-md-6'>
            <img className='img-fluid' src={zscalerceo} alt='Zscalerceopic' />
          </div>
        </div>
      </div>
    </div>
  );
}
