import rp1 from 'assets/images/recent-plantations/zscalerpune-plantations/0.jpg';
import rp22 from 'assets/images/recent-plantations/zscalerpune-plantations/21.jpg';
import rp19 from 'assets/images/recent-plantations/zscalerpune-plantations/18.jpg';
import rp2 from 'assets/images/recent-plantations/zscalerpune-plantations/1.jpg';
import rp3 from 'assets/images/recent-plantations/zscalerpune-plantations/2.jpg';
import rp4 from 'assets/images/recent-plantations/zscalerpune-plantations/3.jpg';
import rp5 from 'assets/images/recent-plantations/zscalerpune-plantations/4.jpg';
import rp6 from 'assets/images/recent-plantations/zscalerpune-plantations/5.jpg';
import rp7 from 'assets/images/recent-plantations/zscalerpune-plantations/6.jpg';
import rp8 from 'assets/images/recent-plantations/zscalerpune-plantations/7.jpg';
import rp9 from 'assets/images/recent-plantations/zscalerpune-plantations/8.jpg';
import rp10 from 'assets/images/recent-plantations/zscalerpune-plantations/9.jpg';
import rp11 from 'assets/images/recent-plantations/zscalerpune-plantations/10.jpg';
import rp12 from 'assets/images/recent-plantations/zscalerpune-plantations/11.jpg';
import rp13 from 'assets/images/recent-plantations/zscalerpune-plantations/12.jpg';
import rp14 from 'assets/images/recent-plantations/zscalerpune-plantations/13.jpg';
import rp15 from 'assets/images/recent-plantations/zscalerpune-plantations/14.jpg';
import rp16 from 'assets/images/recent-plantations/zscalerpune-plantations/15.jpg';
import rp17 from 'assets/images/recent-plantations/zscalerpune-plantations/16.jpg';
// import rp18 from 'assets/images/recent-plantations/zscalerpune-plantations/17.jpg';
import rp20 from 'assets/images/recent-plantations/zscalerpune-plantations/19.jpg';
import rp21 from 'assets/images/recent-plantations/zscalerpune-plantations/20.jpg';

export const zscalerpunegalleryImg = [
  {
    id: 1,
    img: rp1,
    alt: '',
  },
  {
    id: 22,
    img: rp22,
    alt: '',
  },
  {
    id: 19,
    img: rp19,
    alt: '',
  },
  {
    id: 2,
    img: rp2,
    alt: '',
  },
  {
    id: 3,
    img: rp3,
    alt: '',
  },
  {
    id: 4,
    img: rp4,
    alt: '',
  },
  {
    id: 5,
    img: rp5,
    alt: '',
  },
  {
    id: 6,
    img: rp6,
    alt: '',
  },
  {
    id: 7,
    img: rp7,
    alt: '',
  },
  {
    id: 8,
    img: rp8,
    alt: '',
  },
  {
    id: 9,
    img: rp9,
    alt: '',
  },
  {
    id: 10,
    img: rp10,
    alt: '',
  },
  {
    id: 11,
    img: rp11,
    alt: '',
  },
  {
    id: 12,
    img: rp12,
    alt: '',
  },
  {
    id: 13,
    img: rp13,
    alt: '',
  },
  {
    id: 14,
    img: rp14,
    alt: '',
  },
  {
    id: 15,
    img: rp15,
    alt: '',
  },
  {
    id: 16,
    img: rp16,
    alt: '',
  },
  {
    id: 17,
    img: rp17,
    alt: '',
  },
  // {
  //   id: 18,
  //   img: rp18,
  //   alt: '',
  // },

  {
    id: 20,
    img: rp20,
    alt: '',
  },
  {
    id: 21,
    img: rp21,
    alt: '',
  },
];
