import React from 'react';
import { Modal } from 'react-bootstrap';

import Hyderabadplantationvideo from 'assets/images/zscaler/hyderabad-plantation-video.jpg';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='video'>
          <iframe
            src='https://www.youtube.com/embed/PV3An6pNmPY'
            title=''
            description=''
            alt='Headquarters of Heartfulness Institute, Hyderabad'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            frameBorder='0'
            width='100%'
            height='410px'
            webkitallowfullscreen='true'
            mozallowfullscreen='true'
            allowFullScreen
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

function Hyderabadvideo() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div onKeyDown={() => setModalShow(true)} onClick={() => setModalShow(true)} role='button' tabIndex='0'>
        <img className='img-fluid' src={Hyderabadplantationvideo} alt='Universal Prayer' />
      </div>

      <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default Hyderabadvideo;
