import bangaluruvillage from 'assets/images/zscaler/BLR-FBH-Site-1.png';

import punevillage from 'assets/images/zscaler/Pune-FBH-Site.png';

import chandigarhuniversity from 'assets/images/zscaler/chandigrah.png';

import hyderabadinstitude from 'assets/images/zscaler/hyderabad-institude.jpg';

import mohalitower from 'assets/images/zscaler/mohali-tower.jpg';

export const zscalerwhereData = [
  {
    id: 1,
    img: bangaluruvillage,
    alt: 'Bangalaru village',
    villagename: 'Bangalore Office Drive - Neriga village, Off of Sarjapur Rd,',
    city: 'Bangalore',
    saplingname: 'Number of saplings -',
    saplingcount: '5,000',
    acre: '1 acre',
    dateplantation: 'Date of Plantation - 25th June, 2022',
    maplink: 'https://goo.gl/maps/2NF6LijHwMaQ94sG9',
    drivelink: 'https://photos.app.goo.gl/tmqPoH7ceE2ygBfd9',
    officeName: 'View Photo Album',
  },

  {
    id: 2,
    img: punevillage,
    alt: 'Pune village',
    villagename: 'Pune Office Drive - Green Sunrise Hills, Wagholi,',
    city: 'Pune',
    saplingname: 'Number of saplings -',
    saplingcount: '1,000',
    acre: '1 acre',
    dateplantation: 'Date of Plantation - 2nd July, 2022',
    maplink: 'https://maps.app.goo.gl/TJcbjLp5APM5czeG9',
    drivelink: 'https://photos.app.goo.gl/SY5xD7bBK9H9zM2U8',
    officeName: 'View Photo Album',
  },

  {
    id: 3,
    img: hyderabadinstitude,
    alt: 'Chandigarh',
    villagename: 'Hyderabad Office Drive - Kanha Shanti Vanam,',
    city: 'Hyderabad',
    saplingname: 'Number of saplings',
    saplingcount: '7,500',
    acre: '1.5 acre',
    dateplantation: 'Date of Plantation - 6th Aug, 2022',
    maplink: 'https://heartfulness.org/kanha/',
    drivelink: 'https://drive.google.com/drive/folders/1IuXNUt2eyZzcdH09fpJO5r9v4TXWwFyH?usp=sharing',
    officeName: 'View Photo Album',
  },
  {
    id: 4,
    img: mohalitower,
    alt: 'Chandigarh',
    villagename: 'Mohali Office Drive - Heartfulness Meditation Center,',
    city: 'Ambala',
    saplingname: 'Number of saplings',
    saplingcount: '1,500',
    acre: '3 acre',
    dateplantation: 'Date of Plantation - 20th Aug, 2022',
    maplink: 'https://goo.gl/maps/t5DFnxYKiQtVVHzUA',
    drivelink: 'https://drive.google.com/drive/folders/1_qNvzOQ7nmQemOO3_DyYwrxFXZSgZbyV?usp=sharing',
    officeName: 'View Photo Album',
  },
];
