import React from 'react';

// import vadorashramam from 'assets/images/bosch/vadora-ashramam.png';

// import volunteerhand from 'assets/images/bosch/helping-hand-voluteer.png';

// import atftree from 'assets/images/bosch/ATF_Image.png';

import willexecute from 'assets/images/recent-plantations/zscalerpune-plantations/9.jpg';

// import projectexecutepic from 'assets/images/zscaler/execute.jpg';

export default function Projectexecute() {
  return (
    <div className='project-execute inner-pages bg-light-blue-wr' style={{ marginBottom: '30px' }}>
      <div className='container'>
        <div className='row mobi-column-rever'>
          <div className='col-md-7'>
            <img className='vadora-img' src={willexecute} alt='How was this project executed?' />
          </div>
          <div className='col-md-5'>
            <h3 className='title-theme-heading zscaler-theme-blue'>How was this project executed?</h3>
            <p className='execute-p-content zscaler-p-tag'>
              These mini-forests would be created using FBH's{' '}
              <span> proprietary Heartyculture High Density (HCHD) plantation method. </span> This involves extensive
              <span> soil reconstitution using Activated Biochar, Vermicompost, Cow manure and cocopeat. </span> The
              selection of species, besides being native and endemic, will be such that they support one another through
              an{' '}
              <span>
                {' '}
                extensive root network. Within 1 year, the saplings will grow to a height of 10-15 ft, which is several
                times the growth of an regular plantation.{' '}
              </span>{' '}
              And in 2 years' time, the forest would be impenetrable with no sunlight reaching the soil.
            </p>
          </div>
        </div>

      </div>
    </div>
  );
}
