import rp1 from 'assets/images/recent-plantations/zscalerhyderabad-plantations/0.jpg';
import rp2 from 'assets/images/recent-plantations/zscalerhyderabad-plantations/1.jpg';
import rp3 from 'assets/images/recent-plantations/zscalerhyderabad-plantations/2.jpg';
import rp4 from 'assets/images/recent-plantations/zscalerhyderabad-plantations/3.jpg';
import rp5 from 'assets/images/recent-plantations/zscalerhyderabad-plantations/4.jpg';
import rp6 from 'assets/images/recent-plantations/zscalerhyderabad-plantations/5.jpg';
import rp7 from 'assets/images/recent-plantations/zscalerhyderabad-plantations/6.jpg';
import rp8 from 'assets/images/recent-plantations/zscalerhyderabad-plantations/7.jpg';
import rp9 from 'assets/images/recent-plantations/zscalerhyderabad-plantations/8.jpg';

export const zscalerhyderabadgalleryImg = [
  {
    id: 4,
    img: rp4,
    alt: '',
  },
  {
    id: 5,
    img: rp5,
    alt: '',
  },
  {
    id: 2,
    img: rp2,
    alt: '',
  },
  {
    id: 1,
    img: rp1,
    alt: '',
  },
  {
    id: 3,
    img: rp3,
    alt: '',
  },
  {
    id: 6,
    img: rp6,
    alt: '',
  },
  {
    id: 7,
    img: rp7,
    alt: '',
  },
  {
    id: 8,
    img: rp8,
    alt: '',
  },
  {
    id: 9,
    img: rp9,
    alt: '',
  },
];
