import React from 'react';

import kanhashanthivanam from 'assets/images/bosch/kanha-shanthi-vanam.png';

import { Link } from 'gatsby';

import Registernowpopup from 'components/bosch/registerpopup';

import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';

import Photovideo from 'components/zscaler/videoplayer';

import Connectvideo from 'components/zscaler/connectvideo';

import Hyderabadvideo from 'components/zscaler/hyderabadvideo';

import Mohalivideo from 'components/zscaler/mohalivideo';

// import { truncate } from 'lodash';

import { zscalerwhereData } from 'assets/data/zscalerwherewhen';

export default function Videophotograpy() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      // partialVisibilityGutter: 30,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return <div className='carousel-button-group'></div>;
  };

  return (
    <div className='recent-plantation-section bosch-recent-plant hyd-recent-plant  zscalar-plantation-section'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12' style={{ paddingLeft: '0px' }}>
            <div className='section-title'>
              <h3 className='title-theme-heading zscaler-theme-blue' style={{ textAlign: 'left' }}>
                {' '}
                Event Video Gallery{' '}
              </h3>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='section pad-photography'>
            <div className='carousel-section'>
              <Carousel
                arrows={true}
                autoPlaySpeed={3000}
                draggable
                infinite
                keyBoardControl
                minimumTouchDrag={80}
                partialVisible
                renderButtonGroupOutside={true}
                swipeable
                responsive={responsive}
                customButtonGroup={<ButtonGroup />}>
                <div className='mg-bottom20-mobile zscaler-video-slide'>
                  {/* <img src={bangaluruvillage} className='img-fluid' alt='Shanthivanam' /> */}
                  <Photovideo />
                  <div className='card-body where-body zscaler-gthaptik zcaler-body-box' style={{ height: '80px' }}>
                    <p className='zscaler-card-text'>
                      <span>
                        <b>Bangalore Office Drive - Neriga village, Off of Sarjapur Rd, </b>
                      </span>{' '}
                      Bangalore
                    </p>
                  </div>
                </div>
                <div className='mg-bottom20-mobile zscaler-video-slide'>
                  <Connectvideo />
                  <div className='card-body where-body zscaler-gthaptik zcaler-body-box' style={{ height: '80px' }}>
                    <p className='zscaler-card-text'>
                      <span>
                        <b> Pune Office Drive - Green Sunrise Hills, Wagholi, </b>
                      </span>{' '}
                      Pune
                    </p>
                  </div>
                </div>

                <div className='mg-bottom20-mobile zscaler-video-slide'>
                  <Hyderabadvideo />
                  <div className='card-body where-body zscaler-gthaptik zcaler-body-box' style={{ height: '80px' }}>
                    <p className='zscaler-card-text'>
                      <span>
                        <b> Hyderabad Office Drive - Kanha Shanti Vanam, </b>
                      </span>{' '}
                      Hyderabad
                    </p>
                  </div>
                </div>
                <div className='mg-bottom20-mobile zscaler-video-slide'>
                  <Mohalivideo />
                  <div className='card-body where-body zscaler-gthaptik zcaler-body-box' style={{ height: '80px' }}>
                    <p className='zscaler-card-text'>
                      <span>
                        <b> Mohali Office Drive - Heartfulness Meditation Center, </b>
                      </span>{' '}
                      Ambala
                    </p>
                  </div>
                </div>

              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
