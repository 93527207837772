import attractbird from 'assets/images/bosch/attract-bird.png';
import grownfruit from 'assets/images/bosch/grown-fruit.png';
import pollution from 'assets/images/bosch/pollution.png';
import localwater from 'assets/images/bosch/localwater.png';
import smallplant from 'assets/images/bosch/small-plant.png';

export const objectforest = [
  {
    id: 1,
    img: attractbird,
    alt: 'attractbird',
    title: ' <span> Attract significant biodiversity </span> (birds, bees, rodents &amp; reptiles)',
  },

  {
    id: 2,
    img: grownfruit,
    alt: 'grownfruit',
    title: ' Provide naturally grown <span> fruit and medicinal </span> herbs to the local communities',
  },
  {
    id: 3,
    img: pollution,
    alt: 'grownfruit',
    title: '<span>Create a microclimate and abating city pollution</span>',
  },
  {
    id: 4,
    img: localwater,
    alt: 'grownfruit',
    title: '<span>Raise local groundwater table</span>',
  },
  {
    id: 5,
    img: smallplant,
    alt: 'grownfruit',
    title: '<span>Sequester at least 5,000 tons of Carbon over next 10 years</span>',
  },
];
