import React from 'react';

import hfnLogo from 'assets/images/icons/hfn-black.png';

import footboschLogo from 'assets/images/icons/foot-bosch-alt.png';

import boschLogo from 'assets/images/logo/bosch-logo.png';

import heartfulnessLogo from 'assets/images/logo/heartfulness-logo.png';

import fbhgreenLogo from 'assets/images/logo/FBH-Final-Logo.png';

import facebook from 'assets/images/icons/facebook-brown-icon.png';
import twitter from 'assets/images/icons/Twitter-brown.png';
import instagram from 'assets/images/icons/Instagram-brown.png';
import youtube from 'assets/images/icons/youtube-icon.png';
import linkedin from 'assets/images/icons/Linkedin-brown.png';

import zscalerlogo from 'assets/images/zscaler/zscaler-logo.png';

const Footer = () => {
  return (
    <div className='footer-wrapper zscalar-footer-wrapper'>
      <div className='container msg-info-wrapper bosch-info-wrapper' id='contact'>
        <div className='row'>
          {/* <div className='col-md-12'>
            <div className='msg-info bg-brown-wrapper'>
              <p>
                “In case of any queries on how to start your own plantation drive, CSR contributions, suggestions or any
                collaboration opportunities, please write to
              </p>
              <div className='text-center'>
                <button className='btn btn-secondary fhb-btn bosch-theme-bg'>fbh@heartfulness.org</button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <footer className='footer-section bosch-footer-section'>
        <div className='container'>
          <div className='row footer-widget-row position-set'>
            <div className='col-md-4 logo-section footer-widget set-foot-padd'>
              <div className='foot-logo-line'>
                <span>
                  <img className='foot-bosch-logo down-zscaler-logo' src={zscalerlogo} alt='HFN Logo' />
                </span>
                {/* <div className='foot-vertical-separate zscalar-foot-vertical'></div> */}

                <span className='zscaler-vertical-line-footer'> </span>
                <span>
                  <img className='foot-heartulness-logo' src={fbhgreenLogo} alt='heartfulness Logo' />
                </span>
              </div>

              <p className='foot-p-content'></p>
            </div>
            <div className='col-md-2'></div>

            <div className='col-md-6 contact footer-widget bosch-foot-widget'>
              <h3 className='zscaler-theme-blue'>Contact</h3>
              <p className='zscaler-p-tag'> If there are questions you want to ask, we will answer all your questions.</p>
              {/* <p className='phone-number'>
                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#fff' viewBox='0 0 16 16'>
                  <path d='M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z' />
                </svg>
                <span>+91 98765 43210</span>
              </p> */}

              <p className='mb-0'>
                {' '}
                <b className='fn-weight600 zscaler-p-tag'> At Zscaler: </b>{' '}
              </p>
              <p className='font-10'>
                {' '}
                <a href='mailto:z-forestation@zscaler.com' style={{ color: '#000', textDecoration: 'none' }}>
                  {' '}
                  z-forestation@zscaler.com{' '}
                </a>
              </p>
              <hr />

              <p className='mb-0'>
                {' '}
                <b className='fn-weight600 zscaler-p-tag'> At Heartfulness: </b>{' '}
              </p>

              <p>
                <a href='mailto:fbh@heartfulness.org' style={{ color: '#000', textDecoration: 'none' }}>
                  {' '}
                  fbh@heartfulness.org{' '}
                </a>
              </p>
            </div>
          </div>
          <hr className='footer-seprator brown-seprator' />
          <div className='row copy-rights-row pd-bottom20'>
            <div className='col-md-6'>
              <p className='copyright-text'>Copyrights {new Date().getFullYear()}. All rights reserved.</p>
            </div>
            <div className='col-md-6'>
              <ul className='social-icons bosch-social-icon'>
                <li>
                  <a
                    href='https://www.facebook.com/Zscaler/'
                    target='_blank'
                    rel='noreferrer'>
                    <img src={facebook} alt='facebook' />
                  </a>
                </li>
                <li>
                  <a
                    href='https://twitter.com/zscaler'
                    target='_blank'
                    rel='noreferrer'>
                    <img src={twitter} alt='twitter' />
                  </a>
                </li>
                <li>
                  <a
                    href='https://instagram.com/zscalerinc?igshid=YmMyMTA2M2Y='
                    target='_blank'
                    rel='noreferrer'>
                    <img src={instagram} alt='instagram' />
                  </a>
                </li>
                {/* <li>
                  <a href='https://www.youtube.com/c/heartfulness' target='_blank' rel='noreferrer'>
                    <img src={youtube} alt='youtube' />
                  </a>
                </li> */}
                <li>
                  <a
                    href='https://in.linkedin.com/company/zscaler'
                    target='_blank'
                    rel='noreferrer'>
                    <img src={linkedin} alt='linkedin' />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
